import clsx from "clsx";
import ChevronLeftIcon from "../../assets/icons/24/arrow/ChevronLeft";
import ChevronRightIcon from "../../assets/icons/24/arrow/ChevronRight";
import React from "react";
import { omit } from "lodash-es";

const CarouselLeftArrow = ({ className = undefined, ...props }) => {
  return (
    <div
      className={clsx(
        className,
        "flex items-center justify-center w-[32px] md:w-[40px] h-[32px] md:h-[40px] bg-[#282828] bg-opacity-80 rounded-full md:rounded-0 md:bg-transparent before:hidden -left-[8px] md:-left-[48px] z-[50] md:top-[50%] md:-translate-y-[50%]"
      )}
      {...omit(props, ["slideCount", "currentSlide"])}
    >
      <ChevronLeftIcon className="text-white md:text-[#282828]" />
    </div>
  );
};

const CarouselRightArrow = ({ className = undefined, ...props }) => {
  return (
    <div
      className={clsx(
        className,
        "flex items-center justify-center w-[32px] md:w-[40px] h-[32px] md:h-[40px] bg-[#282828] bg-opacity-80 rounded-full md:rounded-0 md:bg-transparent before:hidden -right-[8px] md:-right-[48px] z-[50] md:top-[50%] md:-translate-y-[50%]"
      )}
      {...omit(props, ["slideCount", "currentSlide"])}
    >
      <ChevronRightIcon className="text-white md:text-[#282828]" />
    </div>
  );
};

const CarouselArrows = {
  Left: CarouselLeftArrow,
  Right: CarouselRightArrow,
};

export default CarouselArrows;
