import { useRouter } from "next/router";
import { useMemo } from "react";
import NotFoundUtils from "./NotFoundUtils";

const useNotFoundSlug = () => {
  const { asPath } = useRouter();

  return useMemo(() => {
    return NotFoundUtils.asPathSlug(asPath);
  }, [asPath]);
};

export default useNotFoundSlug;
