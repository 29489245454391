import { SearchProductsItem } from "./NotFoundTypes";
import ProductCard from "@whoppah/components/product/ProductCard";

interface NotFoundCardsMobileProps {
  products: SearchProductsItem[];
}

const NotFoundCardsMobile = ({ products }: NotFoundCardsMobileProps) => {
  return (
    <div className="flex flex-wrap gap-[10px] md:hidden">
      {products.map((product, index) => (
        <ProductCard
          key={product.id}
          item={product}
          listName="404-new-in"
          index={index}
          className="basis-[calc(50%-5px)] min-w-0"
        />
      ))}
    </div>
  );
};

export default NotFoundCardsMobile;
