import React from "react";
import { SearchProductsItem } from "./NotFoundTypes";
import useOffsetWidths from "../hooks/useOffsetWidths";
import Slider from "react-slick";
import ProductCard from "@whoppah/components/product/ProductCard";
import CarouselArrows from "@whoppah/components/Homepage/CarouselArrows";

const SLIDER_RESPONSIVE_SETTINGS = [
  {
    breakpoint: 1280,
    settings: {
      slidesToShow: 5,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 4,
    },
  },
];

interface CardsSliderProps {
  products: SearchProductsItem[];
}

const NotFoundCardsSlider = ({ products }: CardsSliderProps) => {
  const { widths } = useOffsetWidths(["#slider-wrapper"]);
  const sliderWrapperWidth = widths[0] ?? 0;

  const sliderStyleProps: { style: React.CSSProperties } = {
    style: {
      width: sliderWrapperWidth - 48,
      marginLeft: "auto",
      marginRight: "auto",
    },
  };

  return (
    <div id="slider-wrapper" className="flex-1 hidden md:block">
      <Slider
        {...sliderStyleProps}
        infinite={false}
        autoplaySpeed={6000}
        slidesToShow={6}
        slidesToScroll={1}
        prevArrow={<CarouselArrows.Left />}
        nextArrow={<CarouselArrows.Right />}
        responsive={SLIDER_RESPONSIVE_SETTINGS}
      >
        {products.map((product, index) => (
          <ProductCard
            key={product.id}
            item={product}
            listName="404-new-in"
            index={index}
            className="mx-[8px] h-[384px] mb-[16px]"
          />
        ))}
      </Slider>
    </div>
  );
};

export default NotFoundCardsSlider;
