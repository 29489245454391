import Link from "@whoppah/next/link";
import { CANONICAL_DOMAIN } from "config";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { Fragment } from "react";
import { JsonLd } from "react-schemaorg";
import clsx from "clsx";

import ChevronRight from "@whoppah/icons/24/ChevronRight";

const BreadcrumbItem = ({ href, label }) => {
  return (
    <li className="inline-flex items-center font-body">
      {href ? (
        <Link href={href} passHref prefetch={false}>
          <a className="inline-flex whitespace-nowrap text-sm font-medium hover:underline md:whitespace-normal">
            {label}
          </a>
        </Link>
      ) : (
        <a
          disabled
          tabIndex="-1"
          className="inline-flex whitespace-nowrap text-sm font-medium hover:underline md:whitespace-normal"
        >
          {label}
        </a>
      )}
    </li>
  );
};

const Breadcrumb = ({
  items,
  horizontalScroll = false,
  visibleOnMobile = false,
}) => {
  const { t } = useTranslation("common");
  const { locale, defaultLocale } = useRouter();

  const allItems = [{ label: t("common_home"), href: "/" }, ...items];

  const richResults = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: allItems.map((item, index) => {
      const href =
        locale === defaultLocale ? item.href : `${locale}${item.href}`;
      const url = item.href
        ? new URL(href, `https://${CANONICAL_DOMAIN}`)
        : undefined;

      return {
        "@type": "ListItem",
        position: index + 1,
        name: item.label,
        item: url?.href,
      };
    }),
  };

  return (
    <>
      <nav
        className={clsx(
          "mb-2 py-1.5 text-gray-600 dark:text-gray-200 md:py-0",
          visibleOnMobile ? "flex" : "hidden md:flex",
          horizontalScroll && "no-scrollbar overflow-x-auto"
        )}
        aria-label="Breadcrumb"
      >
        <ol className="inline-flex items-center space-x-0.5 text-gray-500">
          {allItems.map((props, index) => (
            <Fragment key={props.label}>
              {index > 0 ? <ChevronRight className="text-grey-500" /> : null}
              <BreadcrumbItem {...props} />
            </Fragment>
          ))}
        </ol>
      </nav>
      <JsonLd item={richResults} />
    </>
  );
};

export default Breadcrumb;
