import { SEO } from "components/common";
import Breadcrumb from "components/common/Breadcrumb";
import { useTranslation } from "next-i18next";
import React, { useCallback, useEffect, useState } from "react";
import useSearchProductsLazy from "@whoppah/apollo/hooks/useSearchProductsLazy";
import { Language, Ordering, SearchSort } from "@whoppah/apollo/schema";
import NotFoundBanner from "./NotFoundBanner";
import NotFoundCards from "./NotFoundCards";
import MarkdownToJSX from "markdown-to-jsx";
import { SearchProductsItem } from "./NotFoundTypes";
import useNotFoundSlug from "./useNotFoundSlug";
import { useRouter } from "next/router";

const NotFound = () => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  const [searchProducts] = useSearchProductsLazy();
  const [products, setProducts] = useState<SearchProductsItem[]>([]);

  const slug = useNotFoundSlug();

  const fetchNew = useCallback(async () => {
    try {
      const result = await searchProducts({
        variables: {
          input: {
            sort: SearchSort.Created,
            order: Ordering.Desc,
          },
          lang: locale?.toUpperCase() as Language,
        },
      });
      setProducts(
        (result.data?.searchProducts?.items?.filter(
          Boolean
        ) as SearchProductsItem[]) ?? []
      );
    } catch (ex) {
      console.error("NotFound ~ fetchNew", ex);
    }
  }, [locale, searchProducts]);

  useEffect(() => {
    fetchNew();
  }, [fetchNew]);

  return (
    <>
      <SEO title={t("404:404-page-title")} />
      <div className="hidden px-4 py-4 sm:px-0 md:block">
        <Breadcrumb items={[{ label: t("404:404-page-title") }]} />
      </div>
      <NotFoundBanner type={slug ? "product" : "page"} />
      {products.length > 0 && (
        <NotFoundCards
          title={<MarkdownToJSX>{t("404:404-new-in")}</MarkdownToJSX>}
          products={products}
        />
      )}
    </>
  );
};

export default NotFound;
