import NotFound from "errors/NotFound";
import getTranslations from "../lib/getTranslations";

const NotFoundPage = () => {
  return <NotFound />;
};

export const getStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await getTranslations(locale, ["404"])),
    },
  };
};

export default NotFoundPage;
