import { SearchProductsItem } from "./NotFoundTypes";
import NotFoundCardsSlider from "./NotFoundCardsSlider";
import React from "react";
import NotFoundCardsMobile from "./NotFoundCardsMobile";

interface CardsProps {
  title: string | JSX.Element;
  products: SearchProductsItem[];
}

const NotFoundCards = ({ title, products }: CardsProps) => {
  return (
    <div className="flex flex-col item-center gap-[16px] mx-[16px] my-[32px]">
      <span className="font-poppins text-[16px] md:text-[24px] text-center">
        {title}
      </span>
      <NotFoundCardsSlider products={products} />
      <NotFoundCardsMobile products={products} />
    </div>
  );
};

export default NotFoundCards;
