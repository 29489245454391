import Image from "@/components/common/Image";
import LeftImage from "@whoppah/images/404-left.png";
import SparkIcon from "@whoppah/icons/24/Spark";
import Button from "@whoppah/ui/Button2";
import HeartIcon from "@whoppah/icons/24/heart/Filled";
import RightImage from "@whoppah/images/404-right.png";
import React from "react";
import { useTranslation } from "next-i18next";

interface NotFoundBannerProps {
  type: "page" | "product";
}

const NotFoundBanner = ({ type = "page" }: NotFoundBannerProps) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-1 md:grid-cols-[auto_1fr_auto] gap-x-[72px] gap-y-[32px] md:gap-y-[60px] mt-[32px] px-[16px]">
      <div className="relative w-[256px] hidden md:block">
        <Image src={LeftImage} alt="404 left image" />
      </div>
      <div className="flex flex-col items-center gap-[8px]">
        <SparkIcon className="w-[74px] h-[50px] text-[#e26f77]" />
        <span className="font-poppins font-semibold text-[32px] md:text-[100px] -mt-[16px] md:-mt-[32px]">
          {t("404:search_results_empty_title")}
        </span>
        <span className="font-poppins text-[14px] md:text-[18px] text-center">
          {type === "page"
            ? t("404:404-page-description")
            : t("404:404-page-description-slug")}
        </span>
        <Button
          iconLeft={<HeartIcon />}
          label={t("404:404-find-new-favorites")}
          color="secondary"
          className="w-full md:w-auto mt-[32px]"
          href="/new"
        />
      </div>
      <div className="relative w-[256px] hidden md:block">
        <Image src={RightImage} alt="404 right image" />
      </div>
      <hr className="md:col-span-3" />
    </div>
  );
};

export default NotFoundBanner;
