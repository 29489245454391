import { LazyQueryHookOptions, useLazyQuery } from "@apollo/client";
import searchProductsGql from "@whoppah/apollo/query/searchProducts.graphql";
import {
  SearchProductsQuery,
  SearchProductsQueryVariables,
} from "@whoppah/apollo/schema";

const useSearchProductsLazy = (
  options?: LazyQueryHookOptions<
    SearchProductsQuery,
    SearchProductsQueryVariables
  >
) => {
  return useLazyQuery(searchProductsGql, options);
};

export default useSearchProductsLazy;
