const ChevronLeft = ({ className = "" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.6891 17.5577C15.1036 17.1431 15.1036 16.4734 14.6891 16.0589L10.5645 11.9343L14.6891 7.80981C15.1036 7.39523 15.1036 6.72552 14.6891 6.31095C14.2745 5.89637 13.6048 5.89637 13.1902 6.31095L8.31093 11.1902C7.89636 11.6048 7.89636 12.2745 8.31093 12.6891L13.1902 17.5683C13.5942 17.9723 14.2745 17.9723 14.6891 17.5577Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronLeft;
