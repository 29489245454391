import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

const useOffsetWidths = (selectors: string[]) => {
  const previousSelectors = useRef(selectors);
  const [widths, setWidths] = useState<number[]>([]);

  const calculateWidths = useCallback(() => {
    setWidths(
      previousSelectors.current.map(
        selector =>
          document.querySelector<HTMLElement>(selector)?.offsetWidth ?? 0
      )
    );
  }, []);

  const handleWindowResize = useCallback(() => {
    calculateWidths();
  }, [calculateWidths]);

  useEffect(() => {
    previousSelectors.current = selectors;
  });

  useLayoutEffect(() => {
    handleWindowResize();
  }, [handleWindowResize]);

  useLayoutEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return { widths, updateWidths: calculateWidths };
};

export default useOffsetWidths;
